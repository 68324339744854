import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  getEscaloes, getJogos, getClassificacao } from '../../store/actions';
import { Accordion, Row, Col, Spinner } from 'react-bootstrap';
import JogosTab from './jogostab';
import { useState } from 'react';

const AcordeaoJogos = ({admin}) => {
    const escaloes = useSelector( state=> state.escaloes);
    const jogos = useSelector( state=> state.jogos);
    const classificacao = useSelector( state=> state.classificacao);
    const dispatch = useDispatch();
    const [campos, setCampos] = useState(null);
    let defaultState = JSON.parse(localStorage.getItem("expandedItem")) || '1';
    let [expandedItem, setExpandedItem] = useState(defaultState);

    useEffect(()=>{
            dispatch(getEscaloes());
            dispatch(getJogos());
            dispatch(getClassificacao());
    },[dispatch])

    const calculaCampos = (jogos) => {
        const result = [];
        const uniqueCamposSet = new Set();

        for (const jogo of jogos) {
            const { campo, campos_id, campodesignacao } = jogo;
            if (!uniqueCamposSet.has(campos_id)) {
                result.push({ campos_id, campo, campodesignacao });
                uniqueCamposSet.add(campos_id);
            }
        }



        return result.sort((a,b)=> b.campos_id - a.campos_id);
    }

    useEffect(() => {
        if (jogos && jogos.jogos && jogos.jogos.length > 0) {
            const camposResult = calculaCampos(jogos.jogos);
            setCampos(camposResult);
        }
    }, [jogos]);


    return(
        <>
            { jogos.jogos && escaloes.escaloes && classificacao.classificacao && campos ?  admin ? 
        (<>
                <Accordion defaultActiveKey={expandedItem}
                onSelect={(e) => {
                    if (e !== null) {
                      setExpandedItem(e);
                      localStorage.setItem("expandedItem", JSON.stringify(e));
                    }
                  }}
                >
                {
               campos ? 
                campos.map((item)=>(
                            <Accordion.Item key={item.campos_id}  eventKey={item.campos_id} >
                            <Accordion.Header>{`Campo ${item.campo} - ${item.campodesignacao}`}</Accordion.Header>
                            <Accordion.Collapse eventKey={item.campos_id}>
                            <Accordion.Body >
                {jogos && jogos.jogos && jogos.jogos.filter && classificacao && classificacao.classificacao &&  classificacao.classificacao.filter ?
                                    <JogosTab admin={admin} escalao={item.id} classificacao={{}} jogos={jogos.jogos.filter((elem)=> elem.campos_id === item.campos_id) }/>
                                    : null
                              }
                              
    </Accordion.Body></Accordion.Collapse>
                            </Accordion.Item>
                            
                    ))     :null     
                }
            </Accordion>
                 </>) :
                        (<>
                            <Accordion activeKey={expandedItem}
                onSelect={(e) => {
                    if (e !== null) {
                      setExpandedItem(e);
                      localStorage.setItem("expandedItem", JSON.stringify(e));
                    }
                  }}
                >
                            {
                            escaloes && escaloes.escaloes ? 
                            escaloes.escaloes.map((item)=>(
                                        <Accordion.Item key={item.id}  eventKey={item.id} >
                                        <Accordion.Header>{item.nome}</Accordion.Header>
                                        <Accordion.Collapse eventKey={item.id}>
                                        <Accordion.Body >
                            {jogos && jogos.jogos && jogos.jogos.filter && classificacao && classificacao.classificacao &&  classificacao.classificacao.filter ?
                                                <JogosTab admin={admin} escalao={item.id} classificacao={classificacao.classificacao.filter((elem)=> elem.escaloes_id === item.id)  } jogos={jogos.jogos.filter((elem)=> elem.escaloes_id === item.id) }/>
                                                : null
                                          }
                                          
                </Accordion.Body></Accordion.Collapse>
                                        </Accordion.Item>
                                        
                                ))     :null     
                            }
                        </Accordion>
                             </>)
                : 
                <Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="secondary" />         
                                </Col>
            </Row>
                
                }
                </>
    )
}

export default AcordeaoJogos;