import React ,{ useEffect } from "react";
import {Row, Col, Form,Button, FloatingLabel,Card} from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {  resetInscricoes } from '../../store/actions';
import { useNavigate } from 'react-router-dom';


const Success = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

    useEffect(()=>{
            dispatch(resetInscricoes());
    },[dispatch])

  const routeChange = () =>{ 
    let path = `/`; 
    navigate(path);
  }

  return ( 
  <> 
   <Row> <Col>                                                                          
    <Card><Card.Header>Obrigado pela inscrição</Card.Header><Card.Body>   
    <div>
      <h3>
        Receberá os detalhes de pagamento no email indicado.
      </h3>
      <Button variant="primary" onClick={routeChange}>
          Ir para página inicial
        </Button>
      </div>
      </Card.Body></Card>                                                                       
    </Col> </Row>
  </>
    )
}
    

export default Success;