import { 
    GET_ESCALOES
} from '../types';


export default function escaloesReducer(state={},action){
    switch(action.type){
        case GET_ESCALOES:
            return { ...state, ...action.payload }
        default:
            return state
    }
}