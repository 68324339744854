import React from 'react';
import Jogo from './jogos';

const ListaJogos = (props) => {

    const jogos = props.jogos ? props.jogos : null;
    return(
        <>
            
            {jogos ? React.Children.toArray(jogos.map(
                (item, index) => {
                    return index !== 0 ?  <>
                    <div className="lista-jogos" >
                        <hr className='horizontal-divider' />
                        <Jogo admin={props.admin} jogo={item} />
                    </div>
                        </> 
                        : 
                        <>
                        <div className="lista-jogos" >
                        <Jogo admin={props.admin} jogo={item} />
                        </div>
                        </>
                }   
            )) : null }         
 
        </>
    )
}

export default ListaJogos;