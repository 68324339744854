import React from 'react';
import {Row, Col, Form, FloatingLabel,Card} from 'react-bootstrap';

const FormDadosEquipa = ({register,errors,setFile}) => {

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileReader = new FileReader();
      const extension =  selectedFile.name.split('.').pop();
      fileReader.readAsArrayBuffer(selectedFile);
      fileReader.onload = () => {
        setFile({extension: extension, result: fileReader.result});
      };
    }
  };


  return ( 
  <> 
  
  {/*<Row>
   <Col>
   <h4>Dados de equipa</h4>
   </Col>
   </Row>
  <hr/>*/}
  <Card  className='mb-3'><Card.Header>Dados de Equipa</Card.Header><Card.Body>  
  <Row mb="1">
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="nomeEquipaId" label="Designação" className="mb-3">
        <Form.Control type="text"  placeholder="Caxinas de baixo fc" {...register('nomeequipa')} disabled />
      </FloatingLabel>
    </Col>
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="escalaoId" label="Escalão" className="mb-3">
        <Form.Control type="text" placeholder="Caxinas de baixo fc" {...register('escalao')} disabled/>
      </FloatingLabel>
    </Col>
  </Row> 
  <Row mb="1">
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="nomeoficialId" label="Nome da Equipa" className="mb-3">
        <Form.Control type="text" isInvalid={errors?.nomeoficial} placeholder="Nome oficial" {...register('nomeoficial')} />
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.nomeoficial?.message}                                                               
          </Form.Control.Feedback>  
      </FloatingLabel>
       
    </Col>
    </Row> 
  <Row mb="1">
    <Col className='col'>
      <FloatingLabel controlId="nomeResponsavelId" label="Nome do Responsável" className="mb-3">
        <Form.Control type="text" isInvalid={errors?.nomeresponsavel} placeholder="Nome responsável" {...register('nomeresponsavel')}/>
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.nomeresponsavel?.message}                                                               
          </Form.Control.Feedback> 
      </FloatingLabel>
       
    </Col>
  </Row> 
  <Row mb="1">
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="contactoId" label="Contacto do Responsável" className="mb-3">
        <Form.Control type="text" isInvalid={errors?.contacto} placeholder="Contacto responsável" {...register('contacto')}/>
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.contacto?.message}                                                               
          </Form.Control.Feedback> 
      </FloatingLabel>
    </Col>
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="emailId" label="Email" className="mb-3">
        <Form.Control type="text" isInvalid={errors?.email} placeholder="Email" {...register('email')}/>
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.email?.message}                                                               
          </Form.Control.Feedback> 
      </FloatingLabel>
    </Col>
  </Row> 
  <Row mb="1">
    <Col className='col'>
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Logotipo - se já participou em edições anteriores com o mesmo logotipo não é necessário o preenchimento.</Form.Label>
        <Form.Control type="file" accept="image/*" placeholder="Logotipo da equipa" {...register('logotipo',{onChange: handleFileChange})} />
      </Form.Group>
    </Col>
  </Row> 
  </Card.Body> 
  </Card>
  </>
    )
}
    

export default FormDadosEquipa;