import { combineReducers } from 'redux';
import escaloes from './escaloes_reducer';
import jogos from './jogos_reducer';
import classificacao from './classificacao_reducer';
import inscricoes from './inscricoes_reducer';
import fisio from './fisio_reducer';
import equipas from './equipas_reducer';
import agenda from './agenda_reducer';
import campos from './campos_reducer';


const appReducers = combineReducers({
    escaloes,jogos, classificacao, inscricoes, fisio, equipas, agenda,campos
})

export default appReducers;