//react component using react-bootstrap components with two buttons. One that routes to a form and another that routes to a list table of data
// Path: src\components\fisio\inicio.js
import React from 'react'; 
import {Row, Col, Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import { useDispatch } from 'react-redux';

const InicioFisio = ({admin}) => {

    const dispatch = useDispatch();  

    const onEnter = () => dispatch({type: "CLEAR_NAVIGATE"});

    useEffect(() => {
        onEnter();
      }, []) 

    return ( 
    <> 
    <Row>
    <hr/>
    </Row>
        <Row>
        <Col className='col d-flex justify-content-center align-items-center'>
        <Link to={ `${admin ? '/admin' : '' }/adicionar`}><Button variant="primary" size="lg">Adicionar Intervenção</Button></Link>
        </Col>
        { admin ?
        <Col className='col d-flex justify-content-center align-items-center'>
        <Link to={ `${admin ? '/admin' : '' }/listar`}><Button variant="primary" size="lg">Listar Intervenções</Button></Link>
        </Col> 
        : ''}
    </Row> 

    </>
    );
    }

    export default InicioFisio;