import React from 'react';
import Classificacao from './classificacao.js';
import _ from "lodash";


const ListaClassificacao = (props) => {
    
    const classificacao = props.classificacao;

    const getGrupos = (arrayClassificacao)=>{
        var unique = [];
        var distinct = [];
        for( let i = 0; i < arrayClassificacao.length; i++ ){
            if( !unique[arrayClassificacao[i].grupo]){
                distinct.push(arrayClassificacao[i].grupo);
                unique[arrayClassificacao[i].grupo] = 1;
            }
        }

        return distinct;
    }
    
    let grupos = getGrupos(classificacao);
    grupos = _.orderBy(grupos);

    return(
        <>
            { grupos && grupos.length && grupos.length > 0 ?
                            React.Children.toArray(grupos.map((item)=>
                                <Classificacao grupo={item} classificacao={classificacao.filter((classi) =>  classi.grupo === item)} />
                            )) : null


            }
        </>
    )
}
    

export default ListaClassificacao;