import React, {useState} from 'react';
import Moment from 'react-moment';
import {Row, Col, Button, Badge} from 'react-bootstrap';
import Image from '../utils/image';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {  iniciaJogo } from '../../store/actions';
import ModalIniciar from '../utils/modalIniciar';

const Jogo = (props) => {
    

    const jogo = props.jogo;
    const admin = props.admin;
    const dispatch = useDispatch();
    const [iniciarToShow, setIniciarToShow] = useState(null);
    const hideModal = () => setIniciarToShow(null);

    const navigate = useNavigate()
        
    const openURL = () => window.open(jogo.campourl,'_blank');

    const Estado = {
        PorIniciar: (props)=> {
            return <div className="dia-hora">
                        <div className="dia">
                            <Moment format="DD/MM">{props.jogo.hora}</Moment>
                        </div>
                        <div className="hora">
                            <Moment format="HH:mm">{props.jogo.hora}</Moment>
                        </div>
                        <div className="grupo-jogo">{props.jogo.fase === 1 ? 'Grupo '+ props.jogo.grupo : '2ª Fase'}
                        </div>
                        <div className="grupo-jogo">Jogo {props.jogo.referencia}
                        </div>
                    </div>;
        },
        
        EmCurso: (props)=>{
            return <div className="dia-hora">
                <div className="resultado">{props.jogo.goloscasa} - {props.jogo.golosfora}
                </div>
                <div className="grupo-jogo">{props.jogo.fase === 1 ? 'Grupo '+ props.jogo.grupo : '2ª Fase'}
                </div>
                <div className="estado-jogo"><Badge bg="success">{props.jogo.estadodesc}</Badge>
                </div>
                <div className="grupo-jogo">Jogo {props.jogo.referencia}
                </div>
            </div>
        },

        Finalizado: (props)=>{
            return <div className="dia-hora">
                <div className="resultado">{props.jogo.goloscasa} - {props.jogo.golosfora}
                </div>
                <div className="grupo-jogo">{props.jogo.fase === 1 ? 'Grupo '+ props.jogo.grupo : '2ª Fase'}
                </div>
                <div className="estado-jogo"><Badge bg="danger">{props.jogo.estadodesc}</Badge>
                </div>
                <div className="grupo-jogo">Jogo {props.jogo.referencia}
                        </div>
            </div>
        }
      }

      const middlePart = ()=>{
          switch(jogo.estado){
                case '1':
                    return  <Estado.EmCurso jogo={jogo} />;
                case '2':
                    return  <Estado.Finalizado jogo={jogo} />;
                case '0':
                    return  <Estado.PorIniciar jogo={jogo} />;
            default: return null;
          }
      }

      const iniciarClicked = ()=>{
        setIniciarToShow(jogo.id);
    }


      const iniciarJogo = async ()=>{
        await dispatch(iniciaJogo(jogo.id));
        hideModal();
        const sAdmin = admin ? 'admin/' : '';
        navigate(`/jogos/${sAdmin}${jogo.id}/`)
      }

      const gotoJogo = async ()=>{
        const sAdmin = admin ? 'admin/' : '';
        navigate(`/jogos/${sAdmin}${jogo.id}/`)
      }


      const getStatusButton = (estado,hora) => {
        //hora is a datetime. validate if the date is today
        const today = new Date();
        const jogoDate = new Date(hora);
        //if(jogoDate.getDate() !== today.getDate() || jogoDate.getMonth() !== today.getMonth() || jogoDate.getFullYear() !== today.getFullYear()){
        //    return null;
        //}

        switch(estado){
            case '1':
                return <Button className="btn-danger btn-sm button-mapa" onClick={gotoJogo}>Editar</Button> ;

            case '0':
                return <Button className="btn-primary btn-sm button-mapa" onClick={iniciarClicked}>Iniciar</Button> ;

            case '2':
                return <Button className="btn-secondary btn-sm button-mapa" onClick={()=>{}} disabled>Finalizado</Button> ;

            default: 
                return null;
        }
      }
      

    return(
        <>
            <Row mb="3">
                <Col className='col-auto d-flex justify-content-start align-items-center'>
                    <div className='localizacao'>
                        <div className="nome-campo">
                            {jogo.campo}
                        </div>
                        {admin ? getStatusButton(jogo.estado, jogo.hora) : <Button className="btn-primary btn-sm button-mapa" onClick={openURL}>Mapa</Button>}
                    </div>
                </Col>
                <Col className='col d-flex justify-content-between align-items-center cursor-pointer' onClick={admin ? null : ()=>gotoJogo()}>
                    <div className="equipa-casa">
                        <div className="simbolo-equipa">
                            <Image name={jogo.simbolocasa} />
                        </div>
                        <div className="nome-equipa">
                            {jogo.equipacasa}
                        </div>
                    </div>
                    {middlePart()}
                    <div className="equipa-fora">
                        <div className="simbolo-equipa">
                            <Image name={jogo.simbolofora} />
                        </div>
                        <div className="nome-equipa">
                            {jogo.equipafora}
                        </div>
                    </div>
                </Col>
            </Row>
            {iniciarToShow && (
        <ModalIniciar show={iniciarToShow} voc={iniciarToShow} closeModal={hideModal} onClose={hideModal} onSubmit={iniciarJogo}/>
            )}
        </>
    )
}
    

export default Jogo;