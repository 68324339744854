import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faMapMarkerAlt, faFutbol, faBus ,faUtensils,faStar,faCalendarDays} from '@fortawesome/free-solid-svg-icons';
import { Textfit } from 'react-textfit';
import { Link } from 'react-router-dom';


const AgendaTableItem = ({ dia, horario, descricao, local, tipo, subtexto, url }) => {
    

    const activityIcon = ()=>{
        switch(tipo){
            case 'JOGO':
                return faFutbol;
            case 'TRANSPORTE':
                return faBus;
            case 'REFEICAO':
                return faUtensils;
            default:
                return faStar;
        }
    };
    
    return (
    <Card className="mb-3" style={{ width: '100%' }}>
      <Card.Body>
        <Card.Title>
        <Textfit mode="single" max={18} forceSingleModeWidth={true}><FontAwesomeIcon className="mr-card-footer" icon={activityIcon()} /> {descricao}</Textfit>
        </Card.Title>
        { subtexto ?
        (<Card.Subtitle className="mb-2 text-muted">
        <FontAwesomeIcon className="mr-card-footer fa-blank" icon={activityIcon()} />{subtexto ?? ''}
        </Card.Subtitle>) : null}
      </Card.Body>
      <Card.Footer>
      <Row>
        <Col>
        { url && url != 'null' ? (<a href={url} target="_blank" style={{ color: 'inherit', textDecoration: 'inherit'}}>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> {local} 
            </a>) : ( <><FontAwesomeIcon icon={faMapMarkerAlt} /> {local} </>) }
        </Col>
        <Col className="text-end">
        <FontAwesomeIcon className="mr-card-footer" icon={faCalendarDays} /><span className="mr-card-footer"> {dia}</span>
        <FontAwesomeIcon className="mr-card-footer" icon={faClock} /> <span className="mr-card-footer">{horario}</span>
        </Col>
      </Row>
    </Card.Footer>
    </Card>
  );
}

  const AgendaTable = ({ items }) => (
    <>
      {items.map((item, index) => (
          <AgendaTableItem {...item} />
      ))}
    </>
  );


  export default AgendaTable;