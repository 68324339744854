import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getJogoById, adicionarGolo, finalizaJogo,  removerMarcador } from '../../store/actions';
import { useParams, useNavigate } from 'react-router-dom';
import Image from '../utils/image';
import ModalCustom from '../utils/modal';
import ModalFinalizar from '../utils/modalFinalizar';
import ModalApagaMarcador from '../utils/modalApagaMarcador';
import MyStopwatch from '../utils/stopwatch';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { set } from 'react-hook-form';


const DetalheJogo = ({ admin }) => {

    const jogo = useSelector(state => state.jogos.jogo);
    const jogadores = useSelector(state => state.jogos.jogadores);
    const marcadores = useSelector(state => state.jogos.marcadores);
    const updatejogo = useSelector(state => state.jogos.updatejogo);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [vocToShow, setVocToShow] = useState(null);
    
    const [removerToShow, setRemoverToShow] = useState(null);
    const hideModalRemover = () => setRemoverToShow(null);

    const hideModal = () => setVocToShow(null);
    const [finalizarToShow, setFinalizarToShow] = useState(null);
    const hideModalFinalizar = () => setFinalizarToShow(null);
    const [goloClicked, setGoloClicked] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getJogoById(id));
    }, [updatejogo]);


    const marcadoresCasa = marcadores && marcadores.filter ? marcadores.filter((item) => item.equipas_id === jogo.equipacasaid) : null;
    const marcadoresFora = marcadores && marcadores.filter ? marcadores.filter((item) => item.equipas_id === jogo.equipaforaid) : null;
    const jogadoresCasa = jogadores && jogadores.filter ? jogadores.filter((item) => item.equipas_id === jogo.equipacasaid && item.tipo == 1) : null;
    const jogadoresFora = jogadores && jogadores.filter ? jogadores.filter((item) => item.equipas_id === jogo.equipaforaid && item.tipo == 1) : null;

    //window.store = useSelector((state) => state);

    const finalizarJogo = async () => {
        dispatch(finalizaJogo(id));
        navigate(admin ? "/admin/jogos/" : "/")
    }

    const marcadorClicked = (obj) => {
        setVocToShow(obj);
    }

    const finalizarClicked = () => {
        setFinalizarToShow(id);
    }

    const removerClicked = (obj) => {
        setRemoverToShow(obj);
    }

    const voltarClicked = () => {
        navigate(admin ? "/admin/jogos/" : "/");
    }

    const removerMarcadorVoc = async (obj) => {
        if (goloClicked)
            return;
        setGoloClicked(true);
        dispatch(removerMarcador(id, obj));
       // dispatch(getJogoById(id));
        hideModalRemover();
        setGoloClicked(false);
    }

    const adicionarMarcador = async (obj) => {
        if (goloClicked)
            return;
        setGoloClicked(true);
        let minutes = 0;
        if (jogo && jogo.datainicio) {
            const today = new Date();
            const startDate = new Date(jogo.datainicio);
            minutes = parseInt(Math.abs(today.getTime() - startDate.getTime()) / (1000 * 60) % 60);
        }
        if (minutes > jogo.tempojogo)
            minutes = jogo.tempojogo;

        obj.minuto = minutes ? minutes : 0;

        await dispatch(adicionarGolo(id, obj));
      //  await dispatch(getJogoById(id));
        hideModal();
        setGoloClicked(false);
    }

    useEffect(() => {
        dispatch(getJogoById(id));
    }, [dispatch, id])

    return (

        <>

            <Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                    <div className="equipa-casa">
                        <div className="simbolo-equipa-lg">
                            {jogo ? <Image name={jogo ? jogo.simbolocasa : null} /> : null}
                        </div>
                        <div className="nome-equipa">
                            {jogo ? jogo.equipacasa : null}
                        </div>
                    </div>
                    <div className="dia-hora">
                        <div className="golos-lg">{jogo ? jogo.goloscasa : null} - {jogo ? jogo.golosfora : null}</div>
                        <div className="grupo-jogo">{jogo && jogo.fase === 1 ? 'Grupo ' + jogo.grupo : '2ª Fase'}</div>
                        {jogo ? <>
                            <MyStopwatch horainicio={jogo ? jogo.datainicio : null} startClock={true} tempojogo={jogo ? jogo.tempojogo : null} />
                        </> : null
                        }
                    </div>
                    <div className="equipa-fora">
                        <div className="simbolo-equipa-lg">{jogo ? <Image name={jogo ? jogo.simbolofora : null} /> : null}</div>
                        <div className="nome-equipa">{jogo ? jogo.equipafora : null}</div>
                    </div>
                </Col>
            </Row>

            <hr className="horizontal-divider" />
            <Row className="mb-3" >
                <Col className="d-flex justify-content-center align-items-start">
                    <ul className="list-unstyled">
                        {marcadoresCasa && marcadoresCasa.length && marcadoresCasa.length > 0 ?
                            React.Children.toArray(marcadoresCasa.map((item) => {
                                return (<>
                                    {item.autogolo === 'Y' ? <li>{ admin ? <Button className="bt-eliminar-golo" variant="danger" size="sm"
                                     onClick={() => removerClicked({marcador_id: item.id, nomejogador: item.nome, nomeequipa: jogo.nome , equipas_id: jogo.equipacasaid})}>X</Button> : null } 
                                     AUTOGOLO - {item.minuto}</li> : <li>{ admin ? <Button className="bt-eliminar-golo" variant="danger" size="sm" onClick={() => removerClicked({marcador_id: item.id, nomejogador: item.nome, nomeequipa: jogo.nome , equipas_id: jogo.equipacasaid})}>X</Button> : null } {item.nome} - {item.minuto}</li>}
                                </>)
                            })) : null
                        }
                    </ul>
                </Col>
                <Col className="d-flex justify-content-center align-items-start">
                    <ul className="list-unstyled ul-fora">
                        {marcadoresFora && marcadoresFora.length && marcadoresFora.length > 0 ?
                            React.Children.toArray(marcadoresFora.map((item) => {
                                return (<>
                                    {item.autogolo === 'Y' ? <li>AUTOGOLO - {item.minuto} { admin ? <Button className="bt-eliminar-golo" variant="danger" size="sm" onClick={() => removerClicked({marcador_id: item.id, nomejogador: item.nome, nomeequipa: jogo.nome , equipas_id: jogo.equipaforaid})}>X</Button> : null }
                                    </li> : <li>{item.nome} - {item.minuto} { admin ? <Button className="bt-eliminar-golo" variant="danger" size="sm" onClick={() => removerClicked({marcador_id: item.id, nomejogador: item.nome, nomeequipa: jogo.nome , equipas_id: jogo.equipaforaid})}>X</Button> : null }</li>}
                                </>)
                            })) : null
                        }
                    </ul>
                </Col>
            </Row>
            <hr className="horizontal-divider" />
            {admin ?
                (
                    <>
                        <Row className="mb-3">
                            <Col className="d-flex justify-content-center align-items-start">
                                <ListGroup >
                                    {
                                        jogadoresCasa && jogadoresCasa.length && jogadoresCasa.length > 0 ?
                                            React.Children.toArray(jogadoresCasa.map((item) => {
                                                return (<>
                                                    <ListGroup.Item data-id={item.id} action onClick={() => marcadorClicked({ nomejogador: item.nome, nomeequipa: jogo.equipacasa, jogadores_id: item.id, jogos_id: jogo.id, autogolo: 'N', equipas_id: item.equipas_id })}>
                                                        {item.numero ? `${item.numero} - ` : ''}{item.nomecurto}
                                                    </ListGroup.Item>
                                                </>)
                                            })) : null
                                    }
                                    {
                                        jogadoresCasa && jogadoresCasa.length && jogadoresCasa.length > 0 ?
                                            (<>
                                                <ListGroup.Item className="list-group-item-danger" data-id={0} action onClick={() => marcadorClicked({ nomejogador: null, nomeequipa: jogo.equipacasa, jogadores_id: null, jogos_id: jogo.id, autogolo: 'Y', equipas_id: jogo.equipacasaid })}>
                                                    AUTOGOLO
                                                </ListGroup.Item>
                                            </>)
                                            : null
                                    }

                                </ListGroup>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-start">
                                <div className="list-group">
                                    {
                                        jogadoresFora && jogadoresFora.length && jogadoresFora.length > 0 ?
                                            React.Children.toArray(jogadoresFora.map((item) => {
                                                return (<>
                                                    <ListGroup.Item data-id={item.id} action onClick={() => marcadorClicked({ nomejogador: item.nome, nomeequipa: jogo.equipafora, jogadores_id: item.id, jogos_id: jogo.id, autogolo: 'N', equipas_id: item.equipas_id })}>
                                                        {item.numero ? `${item.numero} - ` : ''}{item.nomecurto}
                                                    </ListGroup.Item>
                                                </>)
                                            })) : null
                                    }
                                    {
                                        jogadoresFora && jogadoresFora.length && jogadoresFora.length > 0 ?
                                            (<>
                                                <ListGroup.Item className="list-group-item-danger" data-id={0} action onClick={() => marcadorClicked({ nomejogador: null, nomeequipa: jogo.equipafora, jogadores_id: null, jogos_id: jogo.id, autogolo: 'Y', equipas_id: jogo.equipaforaid })}>
                                                    AUTOGOLO
                                                </ListGroup.Item>
                                            </>)
                                            : null
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-3" >
                            <Col className="d-flex justify-content-center align-items-center">
                                <p>Treinador: {jogo ? jogo.treinadorcasa : null}</p>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center">
                                <p>Treinador: {jogo ? jogo.treinadorfora : null}</p>
                            </Col>
                        </Row>
                    </>
                ) : null}
            <Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                    <Button variant="secondary" size="lg" onClick={voltarClicked}>
                        Voltar
                    </Button>
                </Col>
                {admin ?
                    (<Col className="d-flex justify-content-center align-items-center">
                        <Button variant="danger" size="lg" onClick={finalizarClicked}>
                            Finalizar Jogo
                        </Button>
                    </Col>) : null}
            </Row>
            {vocToShow && (
                <ModalCustom show={vocToShow} voc={vocToShow} closeModal={hideModal} onClose={hideModal} onSubmit={adicionarMarcador} />
            )}
            {finalizarToShow && (
                <ModalFinalizar show={finalizarToShow} voc={finalizarToShow} closeModal={hideModalFinalizar} onClose={hideModalFinalizar} onSubmit={finalizarJogo} />
            )}
            {removerToShow && (
                <ModalApagaMarcador show={removerToShow} voc={removerToShow} closeModal={hideModalRemover} onClose={hideModalRemover} onSubmit={removerMarcadorVoc} />
            )}
        </>
    )
}


export default DetalheJogo;