import React, { Component } from 'react';
import {Row, Col, Form,Card} from 'react-bootstrap';
import regulamentopdf from '../../assets/pdf/iv_pombalcup_regulamento.pdf';
import protecaopdf from '../../assets/pdf/iv_pombalcup_protecaodados.pdf';
import condicoespdf from '../../assets/pdf/iv_pombalcup_condicoes.pdf';

const FormDadosConsentimento = ({register,errors}) => {
    return (
        <>
        <Card  className='mb-3'><Card.Header>Consentimentos</Card.Header><Card.Body>  
  <Row mb="2">
  <Col className='col justify-content-center align-items-center'>
  <Form.Check  >
            <Form.Check.Input type="checkbox" {...register('regulamento')} isInvalid={errors?.regulamento} />
            <Form.Check.Label><span style={{ fontWeight: 'bold' }}>Li e aceito os seguintes termos:</span> A Associação Desportiva Pedro Roma enquanto, entidade organizadora da IV POMBALCUP nos próximos dias 21, 22 e 23 de junho de 2024; reserva-se, no direito de condicionar a admissão da participação dos Clubes à aceitação do Regulamento do Torneio; e na prestação de consentimento na recolha de dados e, imagens remetidas e, cuja, ficha de inscrição pressupõe, a sua concordância e, aceitação pelos Clubes Participantes;
- A Organização reserva-se, ainda, no direito de excluir qualquer Participante que, não cumpra as exigências previstas no Regulamento aquando da sua Acreditação no primeiro dia do Torneio;
- Mais, declara não se, responsabilizar por furtos, sinistros ou outros eventos imprevistos ocorridos nos recintos desportivos e, de apoio em que, o Torneio venha, a decorrer;</Form.Check.Label>
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.regulamento?.message}                                                               
          </Form.Control.Feedback> 
          </Form.Check>
    </Col>
  </Row> 
  <Row mb="2">
  <Col className='col justify-content-center align-items-center'>
  <Form.Check type="checkbox" >
            <Form.Check.Input type="checkbox"  {...register('recolha')} isInvalid={errors?.recolha} />
            <Form.Check.Label><span style={{ fontWeight: 'bold' }}>Li e aceito os seguintes documentos:</span> -  <a href = {regulamentopdf} target = "_blank">Regulamento</a>
            -  <a href = {protecaopdf} target = "_blank">Proteção de Dados</a>
            -  <a href = {condicoespdf} target = "_blank">Condições de Acesso</a>
            </Form.Check.Label>
            <Form.Control.Feedback type="invalid">                                                     
            {errors?.recolha?.message}                                                               
          </Form.Control.Feedback> 
          </Form.Check>
    </Col>
  </Row> 
  </Card.Body> 
  </Card>
        </>
    );
    }

    export default FormDadosConsentimento;