import React, { useState } from "react";
import {Row, Col, Form,Button, FloatingLabel,Card, Spinner } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from 'react-redux';
import {  getEquipa } from '../../store/actions';
import { useParams, useNavigate, Navigate } from 'react-router-dom';

const schema = yup.object().shape({
  confirmPassword: yup
    .string()
    .required("A password é obrigatória"),
});

const FormPassword = (props) => {

  window.store = useSelector((state) => state);

    const {
      register,
      reset,
      handleSubmit,
      formState: { errors,isSubmitting }
    } = useForm({
      resolver: yupResolver(schema)
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const idEquipa = useSelector(state => state.inscricoes.equipaid);
  
    const onSubmitHandler = (data) => {
      return new Promise((resolve) => {
        if(data.confirmPassword)
          resolve(dispatch(getEquipa(data.confirmPassword)));
      });
      
    };

  return ( 
  <> 
  {  idEquipa ? <Navigate to="/inscricoes" /> : '' }
   <Row> <Col>                                                                          
    <Card><Card.Header>Insira o código da sua equipa</Card.Header><Card.Body>   
    <Form onSubmit={handleSubmit(onSubmitHandler)} onReset={reset} >                                                                                                                                                                                                           
    <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            type="text"
            placeholder="Id da equipa"
            {...register("confirmPassword", {
              required: "O Id é obrigatório"
            })}
          />
          {errors.confirmPassword && (
            <Form.Text className="text-danger">
              {errors.confirmPassword.message}
            </Form.Text>
          )}
        </Form.Group>                                                                                
        
        {isSubmitting ? (
          <Spinner animation="border" size="sm" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
        ) : (
          <Button disabled={isSubmitting} variant="primary" type="submit">
            Ir
          </Button> 
        )}
                                                                                             
    </Form></Card.Body></Card>                                                                       
    </Col> </Row>
  </>
    )
}
    

export default FormPassword;