import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {  getJogosCampos } from '../../store/actions';
import { Card, Row, Col, Spinner, Form, FloatingLabel } from 'react-bootstrap';
import CamposGrid from './camposGrid';
import MainLayout from '../../hoc/mainLayout';

const ls_campo = () => localStorage.getItem('campo_selected') || 'none';

const Campos = () => {
    const jogosCampos = useSelector( state=> state.campos.campos);
    const dispatch = useDispatch();

    useEffect(()=>{
            dispatch(getJogosCampos());
    },[dispatch]);


    const [{ campo_selected, dia_selected }, setData] = useState({
        campo_selected: ls_campo(),
        dia_selected: 0
    });

    const [campos, setCampos] = useState(null);

    const calculaCampos = (jogos) => {
        const result = [];
        const uniqueCamposSet = new Set();

        for (const jogo of jogos) {
            const { nomecampo, campos_id } = jogo;
            if (!uniqueCamposSet.has(campos_id)) {
                result.push({ campos_id, nomecampo });
                uniqueCamposSet.add(campos_id);
            }
        }



        return result.sort((a,b)=> b.campos_id - a.campos_id);
    }

    useEffect(() => {
        if (jogosCampos && jogosCampos.length > 0) {
            const camposResult = calculaCampos(jogosCampos);
            setCampos(camposResult);
        }
    }, [jogosCampos]);

    function handleCampoChange(event) {
        setData(data => ({ campo_selected: event.target.value, dia_selected: 0 }));
        localStorage.setItem('campo_selected', event.target.value);
    }

    function handleDiaChange(event) {
        setData(data => ({ ...data, dia_selected: event.target.value }));
    }

    const campos_data = campos ? [<option key="campos-0" value="0">Todos</option>, campos?.map((item) => (
        <option key={`campos-${item.campos_id}`} value={item.campos_id}>
            {item.nomecampo}
        </option>
    ))] : null;

    const dia_data =  [
        <option key="dias-1" value="0">Todos</option>,
        <option key="dias-2" value="21">21</option>,
        <option key="dias-3" value="22">22</option>,
        <option key="dias-4" value="23">23</option>,
    ];

    const jogosCampos_filtered = jogosCampos && campos ? jogosCampos.filter(jogo => (campo_selected == 0 || jogo.campos_id == campo_selected) && (dia_selected == 0 || jogo.dia == dia_selected)) : [];

    return (
        <>
            {jogosCampos && campos ? (
                <>
                <MainLayout>
                    <Card>
                        <Card.Header>
                            <Card.Title>Selecionar Campos</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect" label="Campo">
                                        <Form.Select aria-label="Campo"
                                            onChange={handleCampoChange}
                                            value={campo_selected}>
                                            {campos_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect"
                                        label="Dia">
                                        <Form.Select aria-label="Dia"
                                            onChange={handleDiaChange}
                                            value={dia_selected}>
                                            {dia_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </MainLayout>
                    <br />
                    <div className="root-grid">
                    <CamposGrid jogos={jogosCampos_filtered} />
                    </div>
                </>
            ) : (<Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>)
            }
        </>
    );


}

export default Campos;