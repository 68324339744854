import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FloatingLabel, Card } from 'react-bootstrap';



const FormFisioZona = ({ register, errors, fisio }) => {
    const [{ regiao_selected, zona_selected, lateral_selected }, setData] = useState({
        regiao_selected: 'none',
        zona_selected: 'none',
        lateral_selected: 'none'
    });

    const [regioes, setRegioes] = useState(null);
    const [zonas, setZonas] = useState(null);
    const [lateral_select, setLateralSelect] = useState(null);

    const calculaRegioes = (fisio) => {
        //get only distinct values from jogos.escaloes_id
        const result = [];
        const uniqueRegiaoSet = new Set();

        for (const fis of fisio) {
            const { regiao } = fis;
            if (!uniqueRegiaoSet.has(regiao)) {
                result.push({ regiao });
                uniqueRegiaoSet.add(regiao);
            }
        }

        return result;
    }

    const calculazonas = (fisio, regiao_sel) => {
        const result = [];
        const uniqueZonasSet = new Set();

        for (const fis of fisio) {
            const { regiao, zona } = fis;
            if (!uniqueZonasSet.has(zona) && regiao_sel == regiao) {
                result.push({ zona });
                uniqueZonasSet.add(zona);
            }
        }

        return result;
    }

    const calculaLateral = (fisio, regiao_sel, zona_sel) => {
        const result = [];
        const uniqueJogosSet = new Set();

        for (const fis of fisio) {
            const { regiao, zona, lateral } = fis;
            if (!uniqueJogosSet.has(lateral) && regiao_sel == regiao && zona_sel == zona) {
                result.push({ lateral });
                uniqueJogosSet.add(lateral);
            }
        }
        return result;
    }

    const regioes_data = [<option key="regioes-0" value="none" disabled hidden> </option>,regioes?.map((item) => (
            <option key={item.regiao} value={item.regiao}>
                {item.regiao}
            </option>
    ))];

    const zonas_data = [     <option key="zona-0" value="none" disabled hidden> </option>,zonas?.map((item) => (
            <option key={item.zona} value={item.zona}>
                {item.zona}
            </option>
    ))];

    const lateral_data = [<option key="lateral-0" value="none" disabled hidden> </option>,lateral_select?.map((item) => (     
            <option key={item.lateral} value={item.lateral}>
                {item.lateral}
            </option>
    ))];

    useEffect(() => {
        if (fisio && fisio.length > 0) {
            const regiaoResult = calculaRegioes(fisio);
            setRegioes(regiaoResult);
        }
    }, [fisio]);

    function handleRegiaoChange(event) {
        setData(data => ({ zona_selected: 'none', lateral_selected: 'none', regiao_selected: event.target.value }));
        setZonas(calculazonas(fisio, event.target.value));
    }

    function handleZonasChange(event) {
        setData(data => ({ ...data, zona_selected: event.target.value, lateral_selected: 'none' }));
        setLateralSelect(calculaLateral(fisio, regiao_selected, event.target.value));
    }
    function handleLateralChange(event) {
        setData(data => ({ ...data, lateral_selected: event.target.value }));
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Zona da Lesão</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel controlId="floatingSelect" label="Região">

                                <Form.Select isInvalid={errors?.regiao} {...register('regiao', { onChange: (e) => { handleRegiaoChange(e) } })} aria-label="Região" value={regiao_selected}>
                                    {regioes_data}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.regiao?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel controlId="floatingSelect" label="Zona">

                                <Form.Select isInvalid={errors?.zona} {...register('zona', { onChange: (e) => { handleZonasChange(e) } })} aria-label="Zona" value={zona_selected}>
                                    {zonas_data}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.zona?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel controlId="floatingSelect" label="Lateralidade">
                                <Form.Select isInvalid={errors?.lateral} {...register('lateral', { onChange: (e) => { handleLateralChange(e) } })} aria-label="Lateral" value={lateral_selected}>
                                    {lateral_data}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.lateral?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>
    );

}

export default FormFisioZona;