import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import  Image from '../components/utils/image';

const MainLayout = (props) =>{
    return(
        <Container className="mb-5">
           <Row className="mb-3">
                <Col className='d-flex justify-content-center align-items-center'>
                    <Image className='titulo-pombalcup' name='letras_banner.png' />
                </Col>
            </Row>
            {props.children}
        </Container>
    )
}

export default MainLayout;