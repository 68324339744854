import React, { startTransition, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {Row, Col, Form,Button,InputGroup,Alert, FloatingLabel} from 'react-bootstrap';
import FormDadosEquipa from './formDadosEquipa';
import FormDadosFacturacao from './formDadosFacturacao';
import FormDadosStaff from './formDadosStaff';
import FormDadosJogadores from './formDadosJogadores';
import FormPack from './formPack';
import FormDadosConsentimento from './formDadosConsentimento';
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import {  gravarEquipa } from '../../store/actions';
import moment from 'moment';
import {  Navigate } from 'react-router-dom';

const schema = yup.object().shape({
  nomeresponsavel: yup
    .string()
    .required("O nome é obrigatório"),
  escalao: yup.string().required("O escalão é obrigatório"),
  contacto: yup.number("Apenas caracteres numéricos").required("Insira o número de contacto").min(9),
  email: yup.string().email().required("O email não é válido"),
  nomeequipa: yup.string().required("O nome da equipa é obrigatório"),
  moradafaturacao: yup.string().required("A morada de facturação é obrigatória"),
  nif: yup.string().required("O NIF é obrigatório"),
  nomefacturacao: yup.string().required("O nome de faturação é obrigatório"),
  pack: yup.string().required("O pack é obrigatório"),
  nomeoficial: yup.string().required("O nome da equipa é obrigatório"),
  observacoes: yup.string().max(2000),
  regulamento: yup.boolean()
    .required("Tem de aceitar os termpos e condições")
    .oneOf([true], "Tem de aceitar os termpos e condições"),
  recolha: yup.boolean()
    .required("Tem de aceitar os documentos.")
    .oneOf([true], "Tem de aceitar os documentos.")
});

const FormPrincipal = (props) => {

    const dispatch = useDispatch();
    const idEquipa = useSelector(state=>state.inscricoes.equipa.id);
    const maxStaff = useSelector(state=> state.inscricoes.equipa.maxstaff);
    const maxJogadores = useSelector(state=> state.inscricoes.equipa.maxinscritos);
    const minJogadores = useSelector(state=> state.inscricoes.equipa.mininscritos);
    const minStaff = useSelector(state=> state.inscricoes.equipa.minstaff);
    const buildDefaultValues = useSelector((state)=>{
      let returnValues = {};
      if(state.inscricoes){
        if(state.inscricoes.equipa){
          returnValues.email = state.inscricoes.equipa.email ?? '';
          returnValues.escalao = state.inscricoes.equipa.nomeescalao ?? '';
          returnValues.contacto = state.inscricoes.equipa.contacto ?? '';
          returnValues.nomeresponsavel = state.inscricoes.equipa.nomeresponsavel ?? '';
          returnValues.nomeequipa = state.inscricoes.equipa.nome ?? '';
          returnValues.moradafaturacao = state.inscricoes.equipa.moradafaturacao ?? '';
          returnValues.nif = state.inscricoes.equipa.nif ?? '';
          returnValues.nomefacturacao = state.inscricoes.equipa.nomefaturacao ?? '';
          returnValues.pack = state.inscricoes.equipa.pack ?? '';
          returnValues.nomeoficial = state.inscricoes.equipa.nomeoficial ?? '';
          returnValues.valortotal = state.inscricoes.equipa.valortotal ?? '0';
          returnValues.observacoes = state.inscricoes.equipa.observacoes ?? '';
        }//Equipa
        if(state.inscricoes.jogadores && state.inscricoes.jogadores.length && state.inscricoes.jogadores.length > 0){
          state.inscricoes.jogadores.map((elem,idx)=>{
              returnValues[`datanascimento${idx}`] = elem.datanascimento ? moment(elem.datanascimento).format('YYYY-MM-DD') : '';
              returnValues[`numerojogador${idx}`] = elem.numero;
              returnValues[`nomejogador${idx}`] = elem.nome;
          })
        }
        if(state.inscricoes.staff && state.inscricoes.staff.length && state.inscricoes.staff.length > 0){
          state.inscricoes.staff.map((elem,idx)=>{
              returnValues[`numerostaff${idx}`] = elem.designacao;
              returnValues[`nomestaff${idx}`] = elem.nome;
          })
        }
      }
      return returnValues;
    });

    const customValidation = (data) => {
      const errors = {};

      if (data.jogadores?.length < minJogadores ) {
        console.log('Err')
        errors.jogadores = `Para este escalão tem de ter pelo menos ${minJogadores} jogadores inscritos.`;
      }

      if (data.staff?.length < minStaff ) {
        errors.jogadores = `Para este escalão tem de ter pelo menos ${minStaff} elementos de staff inscritos.`;
      }
      return Object.keys(errors).length === 0 ? true : errors;
    };

    const fillJogadoresStaff = (data)=>{
      let i = 0;
        data.jogadores = [];
        data.staff = [];
        //Transformar jogadores
        for(i = 0; i < maxJogadores; i++){
          let objOut = {};
          if(data[`nomejogador${i}`] && data[`nomejogador${i}`].length > 0){// Só passa o objeto se o nome estiver preenchido
              objOut.nome = data[`nomejogador${i}`];
              objOut.numero = data[`numerojogador${i}`] || 0;
              objOut.datanascimento = data[`datanascimento${i}`] || '2023-01-01';
              data.jogadores.push(objOut);
          }
        }
        //Transformar staff
        for(i = 0; i < maxStaff; i++){
          let objOut = {};
          if(data[`nomestaff${i}`] && data[`nomestaff${i}`].length > 0){// Só passa o objeto se o nome estiver preenchido
              objOut.nome = data[`nomestaff${i}`];
              objOut.numero = data[`numerostaff${i}`] || 0;
              data.staff.push(objOut);
          }
        }

        return data;
    }
    
    const customResolver = async (data) => {
      try {
        await schema.validate(data, { abortEarly: false });
      } catch (error) {
        return {
          errors: error.inner.reduce((errors, innerError) => {
            console.log(errors);
            return {
              ...errors,
              [innerError.path]: innerError.message,
            };
          }, {}),
        };
      }
      const customErrors = customValidation(fillJogadoresStaff(data));
      if (customErrors === true) {
        console.log('dewwed')
        return {
          values: data,
          errors: {}
        };
      } else {
        return {
          errors: customErrors,
        };
      }
    }

  const [file, setFile] = useState(null);

    const {
      register,
      handleSubmit,
      getValues,
      control,
      setValue,
      formState: { errors,isSubmitSuccessful,isSubmitting }
    } = useForm({
      resolver: customResolver,
      defaultValues: buildDefaultValues
    });
    
    const onSubmitHandler = (data) => {
      return new Promise((resolve) => {      
        const newData = fillJogadoresStaff(data);
        newData.valortotal = getValorTotal();
        resolve(dispatch(gravarEquipa(idEquipa,newData,file)));
      
      });
    };

    const watchValortotal = useWatch({control,name: 'valortotal', defaultValue: buildDefaultValues.valortotal});

  const getValorTotal = ()=>{
    const values = getValues();
    const newData = fillJogadoresStaff(values);
    let val = 0;
    let total = 0;
    switch(newData.pack){
        case "basico":
          val = 30.0;
          break;
        case "escola":
          val = 50.0;
          break;
        case "hotel":
          val = 100.0;
          break;
    }

    if(val> 0){
      total = (newData?.staff?.length || 0)*val + (newData?.jogadores?.length || 0)*val
    }
    return total;
  }
  const calculateValorTotal = ()=>{
    setValue("valortotal",getValorTotal());
  }
  


  return ( 
  <>  
    {  isSubmitSuccessful ? <Navigate to="/success" /> : ''}
    <Form noValidate  onSubmit={handleSubmit(onSubmitHandler)}>
      <FormDadosEquipa setFile={setFile} errors={errors} register={register} />
      <FormDadosFacturacao errors={errors} register={register} />
      <FormPack errors={errors} register={register} changeNome={calculateValorTotal}/>
      <FormDadosJogadores maxJogadores={maxJogadores} register={register} changeNome={calculateValorTotal} />
      <FormDadosStaff maxStaff={maxStaff} register={register} changeNome={calculateValorTotal}/>
      <FormDadosConsentimento register={register} />
      <Row>
      <Col>
          <FloatingLabel controlId={"observacoesid"} label="Observações" className="mb-3">
            <Form.Control as="textarea" placeholder="Observações" {...register("observacoes")} style={{ height: '100px' }}/>
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-start">
        {errors?.jogadores 
      ? <Alert variant="danger">{errors?.jogadores}</Alert>
      : '' }
      {errors?.recolha 
      ? <Alert variant="danger">{errors?.recolha}</Alert>
      : '' }
      {errors?.regulamento 
      ? <Alert variant="danger">{errors?.regulamento}</Alert>
      : '' }
          </Col>
      </Row>
      <Row>
        <Col className="d-flex flex-row justify-content-start">
      <Button disabled={isSubmitting} className="registerButton" type="submit">
                        {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                        Submeter inscrição
                    </Button>
      <InputGroup className="margin-left-cm" >
      <InputGroup.Text className="card-header">Valor total da inscrição: {watchValortotal}€</InputGroup.Text>
      <Form.Control hidden={true} {...register('valortotal')} />
      </InputGroup>
      </Col>
      </Row>
    </Form>
  </>
    )
}
    

export default FormPrincipal;