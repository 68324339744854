import React from 'react';
import {Row, Col, Table} from 'react-bootstrap';
import _ from "lodash";

const Classificacao = (props) => {
    
    const grupo = props.grupo;
    let classificacao = props.classificacao;

    if(classificacao && classificacao.length && classificacao.length > 0){
        classificacao = _.orderBy(classificacao,['lugar'],['asc']);
    }

    return(
        <>
            <Row mb="1">
                <Col className='col d-flex justify-content-center align-items-center'>
                    <div className='titulo-tabela'>
                            Grupo {grupo}
                    </div>
                </Col>
            </Row>
            <Row mb="3">
                <Col className='col d-flex justify-content-center align-items-center'>
                    <div className='tabela'>
                    <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Equipa</th>
                        <th>P</th>
                        <th>J</th>
                        <th>V</th>
                        <th>E</th>
                        <th>D</th>
                        <th>GM</th>
                        <th>GS</th>
                        </tr>
                    </thead>
                    <tbody>
                        { classificacao ? React.Children.toArray(classificacao.map((item)=>{
                            return (<tr>
                            <td>{item.lugar}</td>
                            <td>{item.nomeequipa}</td>
                            <td>{item.pontos}</td>
                            <td>{item.jogos}</td>
                            <td>{item.vitoria}</td>
                            <td>{item.empate}</td>
                            <td>{item.derrota}</td>
                            <td>{item.marcados}</td>
                            <td>{item.sofridos}</td>
                            </tr>)
                        })): null}
                    </tbody>
                    </Table>
                    </div>
                </Col>
            </Row>
        </>
    )
}
    

export default Classificacao;