import React from 'react';
import {Row, Col, Form, FloatingLabel,Card} from 'react-bootstrap';

const FormDadosStaff = ({register,maxStaff,changeNome}) => {

  const staffElement = (numStaff) => {
    var elements = [];
    for(var i =0; i < numStaff; i++){
        elements.push(<>
        <Row mb="1">
        <Col className='col-lg-2 justify-content-center align-items-center'>
          <FloatingLabel controlId={"numeroStaff"+i+"Id"} label="Designação Staff" className="mb-3">
            <Form.Select defaultValue='' {...register("numerostaff" + i)}>
              <option value=""></option>
                <option value="Treinador">Treinador</option>
                <option value="Tr.Adjunto">Tr.Adjunto</option>
                <option value="Staff">Staff</option>
    </Form.Select>
          </FloatingLabel>
        </Col>
        <Col className='col-12 col-md-10 justify-content-center align-items-center'>
          <FloatingLabel controlId={"nomeStaff"+i+"Id"} label="Nome" className="mb-3">
            <Form.Control type="text" placeholder="Nome" {...register("nomestaff" + i,{onBlur: changeNome})}/>
          </FloatingLabel>
        </Col>
      </Row> 
      </>);
    }
    return elements;
  };

  return ( 
  
   <>
   <Card className='mb-3'><Card.Header>Dados de Staff</Card.Header><Card.Body> 
   { React.Children.toArray(staffElement(maxStaff)) }
   </Card.Body> 
   </Card>
   </>
  
    )
}
    

export default FormDadosStaff;