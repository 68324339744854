import React from 'react';
import AcordeaoJogos from './acordeaoJogos';

const Jogos = ({admin}) => {
    return(
        <>
            <AcordeaoJogos admin={admin} />
        </>
    )
}
    

export default Jogos;