import { 
    GET_CLASSIFICACAO
} from '../types';


export default function classificacaoReducer(state={},action){
    switch(action.type){
        case GET_CLASSIFICACAO:
            return { ...state, ...action.payload }
        default:
            return state
    }
}