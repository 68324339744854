import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import ListaJogos from './listajogos';
import ListaClassificacao from '../classificacao/listaClassificacao';

const JogosTab = (props) => {

    const identifier = props.escalao;
    const jogos = props.jogos;
    const classificacao = props.classificacao;
    return(
        <>
            <Tabs defaultActiveKey={"resultados" + identifier} id={"tab_escalao" + identifier} className="mb-3">
  <Tab eventKey={"resultados" + identifier} title="Resultados">
  <ListaJogos admin={props.admin} escalao={identifier} jogos={jogos} />
  </Tab>
  { !props.admin ? 
  (<Tab eventKey={"classificacoes" + identifier} title="Classificações">
    <ListaClassificacao classificacao={classificacao} />
  </Tab>) : null
  }
</Tabs>
        </>
    )
}

export default JogosTab;