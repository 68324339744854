import React from 'react';
import {Row, Col, Form, FloatingLabel,Card} from 'react-bootstrap';

const FormDadosJogadores = ({register,maxJogadores,changeNome}) => {

  const jogadorElement = (numJogadores) => {
    var elements = [];
    for(var i =0; i < numJogadores; i++){
        elements.push(<>
        <Row mb="1">
        <Col className='col-12 col-md-8 justify-content-center align-items-center'>
          <FloatingLabel controlId={"nomejogador"+i+"id"} label="Nome" className="mb-3">
            <Form.Control type="text" placeholder="Nome" {...register("nomejogador" + i,{onBlur: changeNome})}/>
          </FloatingLabel>
        </Col>
        <Col className='col-6 col-md-2 justify-content-center align-items-center'>
          <FloatingLabel controlId={"numeroJogador"+i} label="Número Camisola" className="mb-3">
            <Form.Control type="text" placeholder="Num." {...register("numerojogador" + i)}/>
          </FloatingLabel>
        </Col>
        <Col className='col-6 col-md-2 justify-content-center align-items-center'>
          <FloatingLabel controlId={"datanascimento"+i+"id"} label="Data Nascimento" className="mb-3">
            <Form.Control type="date" placeholder="01/02/2000" {...register("datanascimento" + i)}/>
          </FloatingLabel>
        </Col>
      </Row> 
      
 
      </>);
    }
    return elements;
  };

  return ( 
  <>
  <Card className='mb-3'><Card.Header>Dados de Jogadores</Card.Header><Card.Body>  
   { React.Children.toArray(jogadorElement(maxJogadores))}
   </Card.Body> 
   </Card>
   </>
    )
}
    

export default FormDadosJogadores;