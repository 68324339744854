import { 
    GET_JOGOS_CAMPOS
} from '../types';


export default function camposReducer(state={},action){
    switch(action.type){
        case GET_JOGOS_CAMPOS:
            return { ...state, campos: action.payload }
        default:
            return state
    }
}