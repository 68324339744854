//react component com uma dropdown de react-bootstrap com os escalões, depois outra dropdown com as equipas e outra dropsdown com o dia. Depois de selecionadas as 3 opções, aparece a tabela com a agenda do dia selecionado
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, FloatingLabel, Form, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getAgenda, getEquipas, getEscaloes } from '../../store/actions';
import AgendaTable from './agendaTable';

const ls_escalao = () => localStorage.getItem('escalao_selected') || 'none';
const ls_equipa = () => localStorage.getItem('equipa_selected') || 'none';
const ls_dia = () => localStorage.getItem('dia_selected') || 'none';
const ls_equipasFiltered = () => JSON.parse(localStorage.getItem('equipas_filtered')) || null;
const ls_showDias = () => localStorage.getItem('showDias') || false;

const Agenda = (props) => {

    const escaloes = useSelector(state => state.escaloes.escaloes);
    const equipasAll = useSelector(state => state.equipas.equipas);
    const agenda = useSelector(state => state.agenda.agenda);
    const dispatch = useDispatch();
    const [equipasFiltered, setEquipas] = useState(ls_equipasFiltered());
    const [showDias, setShowDias] = useState(ls_showDias());


    useEffect(() => {
        dispatch(getEscaloes());
        dispatch(getEquipas());
        dispatch(getAgenda());
    }, [dispatch])

    const [{ escalao_selected, equipa_selected, dia_selected }, setData] = useState({
        escalao_selected: ls_escalao(),
        equipa_selected: ls_equipa(),
        dia_selected: ls_dia()
    });

    function handleEscalaoChange(event) {
        setData(data => ({ equipa_selected: 'none', dia_selected: 'none', escalao_selected: event.target.value }));

        localStorage.setItem('equipa_selected', 'none');
        localStorage.setItem('dia_selected', 'none');
        localStorage.setItem('escalao_selected', event.target.value);
        const filtered = equipasAll.filter(equipa => event.target.value == equipa.escaloes_id).sort((a, b) => a.nomecurto.localeCompare(b.nomecurto));
        setEquipas(filtered);
        localStorage.setItem('equipas_filtered', JSON.stringify(filtered));
        setShowDias(null);
    }

    function handleEquipaChange(event) {
        setData(data => ({ ...data, equipa_selected: event.target.value, dia_selected: '0' }));
        localStorage.setItem('equipa_selected', event.target.value);
        localStorage.setItem('dia_selected', '0');
        setShowDias(true);
        localStorage.setItem('showDias', true);
    }
    function handleDiaChange(event) {
        setData(data => ({ ...data, dia_selected: event.target.value }));
        localStorage.setItem('dia_selected', event.target.value);
    }

    const escaloes_data = escaloes ? [<option key="escaloes-0" value="none" disabled hidden> </option>, escaloes?.map((item) => (
        <option key={`escaloes-${item.id}`} value={item.id}>
            {item.nome}
        </option>
    ))] : [<option key="escaloes-0" value="none" disabled hidden> </option>];

    const equipas_data = [<option key="equipas-0" value="none" disabled hidden> </option>, equipasFiltered?.map((item) => (
        <option key={`equipas-${item.id}`} value={item.id}>
            {item.nomecurto}
        </option>
    ))];

    const dia_data = escaloes && equipasFiltered && showDias ? [
        <option key="dias-1" value="0">Todos</option>,
        <option key="dias-2" value="21">21</option>,
        <option key="dias-3" value="22">22</option>,
        <option key="dias-4" value="23">23</option>,
    ] : [<option key="dias-0" value="none" disabled hidden> </option>];

    const agenda_items = agenda && dia_selected && dia_selected != 'none' && equipa_selected && equipa_selected != 'none' ? agenda.filter((item) => {
        return item.equipa_id == equipa_selected && (dia_selected == 0 || item.dia == dia_selected)
        // Se o dia for = 0 então não faz filtro por dia, caso contrário, devolve apenas os itens de agenda do dia_selected
    }) : null;

    return (
        <>
            {escaloes && equipasAll && agenda ? (
                <>
                    <Card>
                        <Card.Header>
                            <Card.Title>Selecionar Equipa</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect" label="Escalão">
                                        <Form.Select aria-label="Escalão"
                                            onChange={handleEscalaoChange}
                                            value={escalao_selected}>
                                            {escaloes_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect" label="Equipas">
                                        <Form.Select aria-label="Equipas"
                                            onChange={handleEquipaChange}
                                            value={equipa_selected}>
                                            {equipas_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col>
                                    <FloatingLabel controlId="floatingSelect"
                                        label="Dia">
                                        <Form.Select aria-label="Dia"
                                            onChange={handleDiaChange}
                                            value={dia_selected}>
                                            {dia_data}
                                        </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br />
                    {agenda_items && agenda_items.length > 0 ? (
                        <Card>
                            <Card.Header>
                                <Card.Title>Agenda</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <AgendaTable items={agenda_items} />
                            </Card.Body>
                        </Card>
                    ) : null
                    }
                </>
            ) : (<Row className="mb-3">
                <Col className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="secondary" />
                </Col>
            </Row>)
            }
        </>
    );
}

export default Agenda;