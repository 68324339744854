import {
    GET_FISIO_ALL,
    GET_FISIO_LOV,
    CLEAR_NAVIGATE,
    ADD_FISIO
} from '../types';

export default function fisioReducer(state = {}, action) {

    switch (action.type) {
        
        case GET_FISIO_ALL:
            const fisio = action.payload?.fisio;
            if (fisio) {
                return { ...state, fisio: fisio }
            }else
            return { ...state }
        case GET_FISIO_LOV:
            return { ...state, fisiolov: action.payload?.fisio, lesaolov: action.payload?.lesoes }
        default:
            return state;

        case CLEAR_NAVIGATE:
            return { ...state, navigate: null }

        case ADD_FISIO:
            return { ...state, navigate: action.payload?.status }

    }
}