import { 
    GET_EQUIPAS,
    GET_JOGADORES
} from '../types';


export default function equipasReducer(state={},action){
    switch(action.type){
        case GET_EQUIPAS:
            return { ...state, equipas: action.payload }
        case GET_JOGADORES:
            return { ...state, jogadores: action.payload }
        default:
            return state
    }
}