import React from 'react';
import { Button, Modal } from 'react-bootstrap';

function ModalIniciar({ show, voc, onClose, onSubmit, closeModal }) {
    return (
      <Modal
        show={show}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby={`contained-modal-title-${voc.href}`}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id={`contained-modal-title-${voc.href}`}>
            Iniciar Jogo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <>
                Tem a certeza que pretende iniciar o jogo?
            </>
            </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={() => closeModal(voc)} >Cancelar</Button>
            <Button variant="primary" onClick={() => onSubmit(voc)}  >OK</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default ModalIniciar