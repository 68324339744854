import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FloatingLabel, Card } from 'react-bootstrap';

const FormFisioLesao = ({ register, errors, lesao }) => {

    const [lesoes, setLesoes] = useState(null);

    useEffect(() => {
        if (lesao && lesao.length > 0) {
            const lesaoResult = calculaLesao(lesao);
            setLesoes(lesaoResult);
        }
    }, [lesao]);


    const calculaLesao = (table_lesao) => {
        //get only distinct values from jogos.escaloes_id
        const result = [];
        const uniqueLesaoSet = new Set();

        for (const les of table_lesao) {
            const { lesao } = les;
            if (!uniqueLesaoSet.has(lesao)) {
                result.push({ lesao });
                uniqueLesaoSet.add(lesao);
            }
        }

        return result;
    }


    const lesoes_data = [<option key="lesoes-0" value="none" disabled hidden> </option>, lesoes?.map((item) => (
        <option key={`lesao-${item.lesao}`} value={item.lesao}>
            {item.lesao}
        </option>
    ))];

    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title>Lesão</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row className='mb-3'>
                        <Col>
                            <FloatingLabel controlId="floatingSelect" label="Lesão">
                                <Form.Select isInvalid={errors?.lesao} {...register('lesao', {})} aria-label="Lesão" defaultValue="none">
                                    {lesoes_data}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.lesao?.message}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            {/*radiogroup with two radio buttons*/}
                            <Form.Group className="mb-3" controlId="formContacto">
                                <Form.Label>Contacto</Form.Label>
                                <Form.Check
                                    {...register("contacto")}
                                    type="radio"
                                    label="Com contacto"
                                    value="Com contacto"
                                />
                                <Form.Check
                                    {...register("contacto")}
                                    type="radio"
                                    label="Sem contacto"
                                    value="Sem contacto"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col>
                            {/*radiogroup with two radio buttons*/}
                            <Form.Group className="mb-3" controlId="formContacto">
                                <Form.Label>Aptidão</Form.Label>
                                <Form.Check
                                    {...register("aptidao")}
                                    type="radio"
                                    label="Apto"
                                    value="Apto"
                                />
                                <Form.Check
                                    {...register("aptidao")}
                                    type="radio"
                                    label="Inapto"
                                    value="Inapto"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row mb="1">
                        <Col>
                            <FloatingLabel controlId={"observacoesid"} label="Observações" className="mb-3">
                                <Form.Control as="textarea" placeholder="Observações" {...register("observacoes")} style={{ height: '100px' }} />
                            </FloatingLabel>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </>

    )


}


export default FormFisioLesao;