import React from 'react';
import {Row, Col, Form, FloatingLabel,Card} from 'react-bootstrap';

const FormDadosFacturacao = ({register,errors}) => {

  return ( 
  <> 

<Card  className='mb-3'><Card.Header>Dados de Faturação</Card.Header><Card.Body>  
  <Row mb="1">
  <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="nomeFacturacao" label="Nome Faturação" className="mb-3">
        <Form.Control type="text" isInvalid={errors?.nomefacturacao} placeholder="Nome Faturação" {...register('nomefacturacao')}/>
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.nomefacturacao?.message}                                                               
          </Form.Control.Feedback> 
      </FloatingLabel>
    </Col>
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="nifId" label="NIF" className="mb-3">
        <Form.Control type="text" isInvalid={errors?.nif} placeholder="NIF" {...register('nif')}/>
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.nif?.message}                                                             
          </Form.Control.Feedback> 
      </FloatingLabel>
    </Col>
  </Row> 
  <Row mb="1">
    <Col className='col justify-content-center align-items-center'>
      <FloatingLabel controlId="moradaFaturacao" label="Morada de Faturação" className="mb-3">
        <Form.Control type="text" isInvalid={errors?.moradafaturacao} placeholder="Morada de Faturação" {...register('moradafaturacao')}/>
        <Form.Control.Feedback type="invalid">                                                     
            {errors?.moradafaturacao?.message}                                                               
          </Form.Control.Feedback> 
      </FloatingLabel>
    </Col>
  </Row> 
  </Card.Body> 
  </Card>
  </>
    )
}
    

export default FormDadosFacturacao;